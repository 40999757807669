import React from "react"
import { Link } from "gatsby"
import image2 from "../../images/marketing/svg/footer/NCPDP-logo.png"
import image3 from "../../images/marketing/svg/footer/NCPA-logo.png"

export default () => (
  <>
    <section id="ez-subscribe">
      <div className="contents">
        <div className="subscribe-box">
          <div className="title-box">
            <h2>EzriRx is 100% free.</h2>
          </div>
          <p>
            Sign up to EzriRx today to gain access to unbeatable savings on
            pharmaceuticals! EzriRx is risk free, obligation free, and you can
            cancel at any time.
          </p>
          <div className="buttons-box">
            <a
              rel="nofollow"
              href="https://app.ezrirx.com/signup/pharmacy/"
              className="button blue-md"
            >
              Get started
            </a>
          </div>
        </div>
      </div>
    </section>

    <footer className="footer">
      <div className="contents clearfix">
        <div className="footer__company">
          <Link to="/" className="text-hide footer__company__logo">
            Ezrirx
          </Link>
          <p>
            builds the most powerful and flexible tools 
            for pharmaceutical purchasing. We bring together 
            the most forward thinking pharmacies and wholesalers 
            onto one incredible platform.
          </p>

          <div className="footer__company__as-seen-at">
            <h4>Corporate members of</h4>
            <ul className="list-inline as-seen-at__logos">
              <li>
                <img
                  src={image2}
                  alt="National Council for Prescription Drug Programs"
                  height="27"
                ></img>
              </li>
              <li>
                <img
                  src={image3}
                  alt="National Community Pharmacists Association"
                  height="27"
                ></img>
              </li>
            </ul>
          </div>

          <p style={{ fontSize: "0.9rem", marginTop: "40px" }}>
            Copyright &copy; {new Date().getFullYear()} EzriRx. All Rights Reserved
          </p>
        </div>

        <div className="footer__links text-center">
          <div className="links col-12 col-sm-3">
            <h3>Features</h3>
            <ul className="list-unstyled">
              <li>
                <Link to="/daily-rx-discounts/">Daily deals</Link>
              </li>
              <li>
                <Link to="/pharmacy-payment-management/">Online payments</Link>
              </li>
              <li>
                <Link to="/pharmacy-order-management/">Order management</Link>
              </li>
              <li>
                <Link to="/DSCSA-compliance/">DSCSA - Pedigree</Link>
              </li>
              <li>
                <Link to="/pharmacy-to-pharmacy-marketplace/">RPh2RPh -
                  <em className="p2p-item">Pharmacy To Pharmacy
                  Marketplace</em></Link>
              </li>
            </ul>
          </div>

          <div className="links col-12 col-sm-3">
            <h3>Company</h3>
            <ul className="list-unstyled">
              <li>
                <Link to="/about/">About</Link>
              </li>
              <li>
                <Link to="/careers/">Careers</Link>
              </li>
            </ul>
          </div>

          <div className="links col-12 col-sm-3">
            <h3>Legal</h3>
            <ul className="list-unstyled">
              <li>
                <Link to="/legal/terms/">Term of service</Link>
              </li>
              <li>
                <Link to="/legal/privacy/">Privacy policy</Link>
              </li>
            </ul>
          </div>

          <div className="links col-12 col-sm-3">
            <h3>Contact us</h3>
            <ul className="list-unstyled">
              <li>
                <Link to="/contact/">Get in touch with us</Link>
              </li>
              <li>
                2360 Rt 9. Suite 3<br/> #171 - Toms River,<br/> NJ 08755
              </li>
              <li>
                <a href="tel:718-502-6610" className="telephone" rel="nofollow">
                  (718) 502-6610
                </a>
              </li>
              <li>
                <a href="mailto:support@ezrirx.com">support@ezrirx.com</a>
              </li>
            </ul>

            <ul className="list-inline socials">
              <li>
                <a
                  href="https://twitter.com/ezrirx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-tw"
                >
                  <i className="text-hide">Twitter</i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/EzriRx-1708841972726530/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-fb"
                >
                  <i className="text-hide">Facebook</i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </>
)
