import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import contactImg from "../images/marketing/svg/contact-illus.svg"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Contact Us - EzriRx.com - Marketplace Platform for 65,000+
        Pharmaceuticals
      </title>
      <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
      <meta
        name="description"
        content="If you have questions about the EzriRx pharmacy portal and how it can save your pharmacy an average of 30% on all drugs, please contact us today!"
      />
      <link rel="canonical" href="https://www.ezrirx.com/contact/" />
      <body className="no-hero-layout" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="marketing-content" style={{ paddingTop: "150px" }}>
          <div className="contents">
            <div className="contact-wrap">
              <div className="contact-header">
                <div className="title-box">
                  <h1>Let’s get in touch.</h1>
                  <p>Need to contact us? Chat with us or send us an email.</p>
                </div>

                <button
                  onClick={() => window.Intercom("show")}
                  className="button blue-md help-link"
                >
                  Chat with us
                </button>
                <p className="contact-header__notice">
                  Response delay by chat is usually 1 to 5 minutes.
                </p>
              </div>

              <img
                src={contactImg}
                alt="New deals. Every day. Never miss incredible savings on Pharmaceuticals."
                className="contact-hero"
              ></img>

              <div className="contact-form">
                <div>
                  <h2>Send us an email</h2>
                  <h3><a href="mailto:support@ezrirx.com">support@ezrirx.com</a></h3>
                  <p className="contact-form__notice">
                    Response delay by email is usually 6 hours{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
